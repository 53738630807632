.footer {
	width: 100%;
	background-color: $white;
	// box-shadow: 0px -2px 8px 0px #f1f1f1;
	display: flex;
	justify-content: center;
	position: relative;
	margin-top: 40px;
	padding-bottom: 40px;
}
