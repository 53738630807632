// ============================================================================
//
// COMPONENTS - INFO
//
// ============================================================================
.info-browser {
	display: flex;
	gap: 0.5rem;
	opacity: 0.8;

	.mat-icon {
		height: 20px;
		width: 20px;
		overflow: inherit;
	}
}
