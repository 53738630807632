// ============================================================================
//
// COMPONENTS - HEADER
//
// ============================================================================

.header-content {
	box-shadow: $box-shadow;
	background-color: $white;
	height: 4rem;
	padding: 1rem;
	position: fixed;
	z-index: 2;
	top: 0;
	width: 100%;

	&__logo {
		display: flex;
		flex-direction: row;
		align-items: flex-start;

		.header__logo-isotipo {
			margin-right: 0.48rem;
			margin-top: 4px;
			width: 1.2rem;
		}

		.header__logo-ikigii {
			width: 3rem;
			margin-top: 4px;
			margin-right: 0.5rem;
		}
		.header__logo-tower {
			margin-top: -1px;
			width: 7rem;
		}
	}

	&__close {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		height: 2rem;

		.p-button.p-button-danger.p-button-text {
			color: transparent;
			background-color: transparent;
			border: 1px solid transparent;
			border-radius: 0;
			&:enabled:focus {
				border: 1px solid transparent;
				box-shadow: none;
			}
		}
	}
	@media only screen and (max-width: 767px) {
		.header__logo-tower {
			margin-top: .25rem;
			width: 6.3rem;
		}

		&__close {
			transform: translateX(1rem);
			
			&-icon {
				height: 1.2rem;
			}
		}

		.container {
			padding: 0;
		}
	}
}
