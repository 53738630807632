.download-content {
	padding-top: 8rem;

    &__title {
		color: $black;
		font-family: $semi-bold;
		font-size: $h1-font-size;
		text-align: center;
		margin: 1rem 0 1.25rem 0;
	}
}