// ============================================================================
//
// COMPONENTS - BUTTONS
//
// ============================================================================
// =============================================================================
//
// Button Class:
//
// .btn--primary
//
// =============================================================================

@mixin btn {
  max-width: 320px;
  width: 100%;
  padding: 0.5rem 1rem;
  height: 60px;
  cursor: pointer;
  border: 2px solid $primary-button-border;
  border-radius: 0.5rem;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  line-height: 1.5rem;
  font-size: 1rem;
  font-family: $semi-bold;
}

@mixin small {
  max-width: 160px;
  width: 100%;
  padding: 0.5rem 0.5rem;
  height: 46px;
  cursor: pointer;
  border: 2px solid $primary-button-border;
  border-radius: 0.5rem;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  line-height: 1.5rem;
  font-size: 1rem;
  font-family: $semi-bold;
}

.btn--primary {
  @include btn;
  width: 100%;
  color: $white;
  background: $primary-button-bg;
  border: 2px solid $primary-button-border !important;

  &:hover {
    background: $primary-button-bg--hover;
    border: 2px solid $primary-button-border--hover !important;
  }

  &:disabled {
    background: $primary-button-bg--disabled;
    border: 2px solid $primary-button-bg--disabled !important;
    color: $primary-button-color--disabled;
    cursor: initial;
  }
}

.btn--primary--small {
  @include small();
  width: 100%;
  color: $white;
  background: $primary-button-bg;
  border: 2px solid $primary-button-border !important;

  &:hover {
    background: $primary-button-bg--hover;
    border: 2px solid $primary-button-border--hover !important;
  }

  &:disabled {
    background: $primary-button-bg--disabled;
    border: 2px solid $primary-button-bg--disabled !important;
    color: $primary-button-color--disabled;
    cursor: initial;
  }
}

.btn--secondary {
  @include btn;
  color: $primary-color;
  background: $secondary-button-bg;
  border: 2px solid $secondary-button-border !important;

  &:hover {
    background: $secondary-button-bg--hover !important;
    border: 2px solid $secondary-button-border--hover !important;
  }

  &:disabled,
  .disabled {
    background: $secondary-button-bg--disabled;
    border: 2px solid $secondary-button-border--disabled !important;
    color: $secondary-button-color--disabled;
    cursor: initial;
  }
}
.btn--addInput {
  cursor: pointer;
  appearance: none;
  line-height: 1.5rem;
  font-size: 1rem;

  padding: 0.5rem 0;
  color: $primary-button-border;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid $white;
  flex-wrap: nowrap;
  text-wrap: nowrap;

  span {
    margin-left: 40px;
  }
}

.btn-terms {
  @include btn;
  text-align: left;
  line-height: 1;
  flex-wrap: nowrap;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;

  &__text {
    img {
      margin-right: 4px;
    }
    display: flex;
    align-items: center;
    font-size: $h6-font-size;
    color: $primary-color;
  }

  &__icons {
    display: flex;
    flex-shrink: 0;
    .arrow {
      color: $primary-color;
    }
  }
}
