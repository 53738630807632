.user-content {

    &__title {
        color: $black;
		font-family: $semi-bold;
		font-size: $h1-font-size;
		text-align: center;
		margin: 3rem 0 1.25rem 0;
    }

    &__subtitle {
        text-align: center;
        font-family: $regular;
        font-size: $h5-font-size;
    }

    .p-password {
        width: 100%;

        .p-password-input {
            width: 100%;
        }
        .p-password-panel {
            display: none;
        }
    }

}