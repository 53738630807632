.terms-content {

  &__title {
    color: $black;
    font-family: $semi-bold;
    font-size: $h1-font-size;
    text-align: center;
    margin: 3rem 0 1.25rem 0;
  }

  &__subtitle {
    text-align: center;
    font-family: $regular;
    font-size: $h6-font-size;
  }
}
.modals {
  p-dialog {
    .modals-content {
      overflow-y: auto;
      border: 1px solid $black;
      max-height: 75vh;
      padding-bottom: 4rem;
      @media only screen and (max-width: 767px) {
        border: 0;
      }
    }
    .p-dialog {
      h3 {
        margin: 1rem;
        @media only screen and (max-width: 767px) {
          margin: 1rem 0;
        }
      }
      p {
        text-align: left;
        font-weight: normal;
        font-family: $regular;
        font-size: 1.1rem;
        padding: 1rem;

        @media only screen and (max-width: 767px) {
          font-size: 0.9rem;
          padding: 0.5rem;
        }
      }
      .p-dialog-header {
        display: flex;
        flex-direction: row-reverse;
      }
      .p-dialog-title {
        display: none;
      }
      max-height: 100%;
      &-content {
        overflow-y: hidden;
      }
    }
  }
}
