// ============================================================================
//
// COMPONENTS - NEED PERMISSIONS
//
// ============================================================================

.need-permissions {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__img {
    width: $dp * 25;
    margin-bottom: $dp * 4;
  }

  &__icon {
    width: 100% !important;
      height: auto !important;
      font-size: $dp * 12;
      margin-bottom: $dp * 4;
      text-align: center;
      color: $primary-color;
  }

  &__title {
    font: 600 32px/40px Roboto, "Helvetica Neue", sans-serif !important;
  }

  &__info {
    font: 400 18px/40px Roboto, "Helvetica Neue", sans-serif !important;
  }
}
