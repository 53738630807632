.verification-content {
  padding-top: 3rem;

  .title {
    color: $black;
    font-family: $semi-bold;
  }
  .subtitle {
    font-family: $regular;
    font-size: $h6-font-size;
    color: $black;
    font-style: normal;
    font-weight: 400;
  }
}

@media only screen and (max-width: 767px) {

  .verification-content {
    padding-top:1rem;

    .subtitle {
      padding: 0;
    }
    .xs-device {
      margin-left: 0;
      margin-right: 0;
    }
    .card-message {
			//margin: 1.6rem;
			flex-direction: column;

			img {
				width: 5.5rem;
				margin: 0 auto 1rem;
			}
		}
  }
}
