.job-content {

	.subtitle {
		text-align: center;
		@media only screen and (max-width: 767px) {
			text-align: left;
		}
	}

	.label-input {
		display: block;
		// width: 100%;
		text-align: left;
	}

	&__title {
		color: $black;
		font-family: $semi-bold;
		font-size: $h1-font-size;
		text-align: center;
		margin: 2rem 0;
	}
}

@media only screen and (max-width: 767px) {
	.job-content {
		padding: 1rem 0.5rem;

		&__title {
			text-align: left;
			font-size: 1.4rem;
			margin: 0 0 1.5rem 0;
		}

		.formgrid.grid {
			margin: 0;
		}
		.xs-device {
			margin-left: 0;
			margin-right: 0;

			// .grid {
			// 	margin: 0 0.2rem !important;
			// }

			.field {
				padding: 0;
			}
		}
	}
}
