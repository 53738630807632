.loading-content {

	&__title {
		color: $black;
		font-family: $semi-bold;
		font-size: $h1-font-size;
		text-align: center;
		margin: 3rem 0;
	}

	&__subtitle {
		text-align: center;

		@media only screen and (max-width: 767px) {
			
		}

		span {
			font-family: $bold;
		}
	}

	.label-flex {
		width: 250px;

		img {
			width: 28px;
		}
	}

	.file-uploaded {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__text {
			display: flex;
			.text,
			.size {
				color: #028759;
				font-size: 1rem;
			}
			.text {
				border: 0;
				width: 210px;
				white-space: nowrap;
				overflow: hidden;
			}
			.size {
				border: 0;
				width: 60px;
			}
		}
		&__actions {
			display: flex;
			flex-direction: row;

			.actions-check {
				width: 30px;
			}

			.actions-delete {
				cursor: pointer;
				background-color: transparent;
				border: 0;
				margin: 0 0 0 5px;
				padding: 0 0 0 5px;
			}
		}
	}
	@media only screen and (max-width: 767px) {
		.xs-device {
			align-items: start !important;
			justify-content: flex-start !important;
		}
		&__title {
			font-size: $h2-font-size;
			line-height: normal;
			text-align: left;
			margin: 1rem .5rem;
		}
		&__subtitle {
			text-align: left;
			margin: 0 .5rem;
		}
	}
}

.income-certificate {
	width: 100%;
}
