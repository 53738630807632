.contact-content {
    padding-top: 8rem;
    
    &__title {
        color: $black;
		font-family: $semi-bold;
		font-size: $h1-font-size;
        margin-top: 40px;
    }

    &__text {
        color: $black;
		font-family: $semi-bold;
        font-size: 1.25rem;
    }

    @media only screen and (max-width: 767px) {
        .xs-device {
            margin-top: 5rem;
    
            .xs-flex-ord-1 {
                order: 1;
                img {
                    position: absolute;
                    transform: scale(0.9);
                    top: 5rem;
                    left: 1rem;
                }
            }
            .xs-flex-ord-2 {
                order: 2;
                margin: 16.5rem 1rem 0;
            }
        }
        &__title {
            font-size: $h2-font-size;
            line-height: normal;
        }
        &__text {
            margin-top: .5rem;
            font-family: $regular;
            font-size: 1.125rem;
        }
    }
}