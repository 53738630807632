// ============================================================================
//
// COMPONENTS - THEME SELECTOR
//
// ============================================================================

// selector theme

.theme-switch {
	display: flex;
	&__btn {
		background-color: var(--btn-global-bg);
		display: flex;
		padding: 0.5rem 0.8rem;
		margin-left: -2px;
		border-radius: 2px;
		border: 1px solid #d3d3d3;
		cursor: pointer;
		span {
			color: var(--btn-tx-color);
		}

		&--selected {
			background-color: var(--btn-selected-bg);
		}

		&:hover {
			background-color: var(--btn-selected-bg);
		}
	}
}
