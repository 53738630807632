header {
	padding: 30px 84px;
	.header-content__logo {
		@media (max-width: 768px) {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.welcome-content {
	background-image: url('../../assets/images/layers/layer-welcome.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 100px;
	width: 100%;
	height: 85vh;
	@media (max-width: 768px) {
		background-position: 0 140px;
		height: auto;
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.text-subtitle {
			text-align: center;
			color: initial;
			@media (max-width: 500px) {
				text-align: left;
			}
		}

		.text-title {
			font-size: 2.25rem;
			margin-bottom: 16px;
			text-align: center;
			@media (max-width: 768px) {
				font-size: 1.5rem;
			}
			@media (max-width: 500px) {
				font-size: 1.4rem;
			}
		}

		.text-subtitle {
			font-size: 1.375rem;
			@media (max-width: 500px) {
				font-size: 1.125rem;
			}
		}
	}

	&__card {
		background-color: $white-1;
		border-radius: 1.25rem;
		margin-top: 36px;
		margin-bottom: 40px;
		padding: 54px 96px;
		@media (max-width: 500px) {
			padding: 30px 26px;
		}

		.card-content__product {
			&-title {
				margin: 0;
				font-family: $regular;
				font-size: 1.375rem;
				font-weight: normal;
				margin-bottom: 36px;
				@media (max-width: 500px) {
					font-size: 1rem;
					margin-bottom: 26px;
				}
			}

			&-subtitle {
				display: flex;
				align-items: center;
				margin-bottom: 1.375rem;
				margin-top: 40px;

				@media (max-width: 500px) {
					margin-bottom: 0.875rem;
				}

				.product-title {
					margin-left: 1rem;
					font-size: 1rem;
					font-family: $bold;
				}
			}

			&-list {
				list-style: initial; 
				padding-left: 30px;

				& > li {
					margin-bottom: 10px;

					.tn {
						font-size: 15px;
						font-weight: 400;
						line-height: 20px;

						.big-font {
							font-family: $bold;
							font-size: 1.1875rem;
						}
					}
	
					.tb {
						font-size: 15px;
						font-weight: 600;
						line-height: 20px;
					}
				}
			}
		}

		.card-content__email {
			&-label {
				display: flex;
				align-items: center;
				margin-bottom: 20px;

				.label-title {
					margin-left: 1rem;
					font-size: 1.125rem;
				}
			}
			&-form {
				.form-input {
					border-radius: 8px;
					background-color: $white;
				}
			}

			.p-button-success-welcome {
				width: 100%;
				height: 60px;
				background-color: #1dc57d;
				border-color: #1dc57d;
				justify-content: center;
				font-family: $medium;

				@media only screen and (min-width: 768px) {
					max-width: 270px;
				}
			}

			.p-button-info-welcome {
				width: 100%;
				height: 60px;
				background-color: transparent;
				border-color: $primary-color;
				color: $primary-color;
				font-family: $medium;
				justify-content: center;

				@media only screen and (min-width: 768px) {
					max-width: 270px;
				}
			}

			.p-button-success-welcome:focus {
				background: #1dc57d;
				color: #1f2d5e;
			}

		}

	}

	&__disclaimer {
		background-color: $white;
		margin-bottom: 80px;
	}
}

.welcome-account {
	background-color: $white;
	padding-top: 60px;
	padding-bottom: 60px;

	@media (max-width: 500px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	&__products {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;

		.button-primary-welcome {
			width: 60%;
			height: 60px;
			background-color: $primary-color;
			border-color: #1dc57d;
			justify-content: center;
			@media (max-width: 1024px) {
				width: 100%;
			}
		}

		@media (max-width: 1024px) {
			flex-direction: column;
			align-items: initial;
		}

		.selectedButton, .unselectedButton {
			cursor: pointer;
			display: flex;
			align-items: center;
			border-radius: 999px;
			padding: 12px 112px 12px 12px;
			@media (max-width: 1024px) {
				padding: 12px 16px;
			}
		}

		.products-fields {
			@media (max-width: 1024px) {
				margin-bottom: 16px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.selectedButton {
			border: 2px solid #2D5FBF;
			background: var(--White, #FFF);
			.textButtonCheck {
				color: initial;
			}
		}

		.unselectedButton {
			border: 1px solid var(--Gray-300, #D0D5DD);
			background-color: $white;
		}

		.custom-radio {
			position: relative;
			width: 28px;
			height: 28px;
			-webkit-appearance: none;
			appearance: none;
			border: none;
			outline: none;
			cursor: pointer;
			margin: 0;
			border: 1px solid #c6c6c6;
			border-radius: 50%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			&:checked {
				background-image: url('/assets/svg/welcome/radioButton.svg');
			}
		}

		.textButtonCheck {
			font-size: 1rem;
			color: #667085;
			margin-left: 6px;
			@media (max-width: 500px) {
				font-size: 12px;
			}
		}
	}
}

::ng-deep .tn {
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 20px !important;
} 

