.breadcrumb-content {
	margin-top: 8rem;

	&__back {
		margin-left: 2rem !important;
		.label-back {
			color: $primary-color;
			font-family: $semi-bold;
			font-size: 1rem;
			cursor: pointer;
		}
	}

	&__item {
		padding-left: 0rem;
		margin-bottom: 12px;
	}

	&__step {
		padding: 0.5rem 20rem;
		span:nth-child(1) {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}

		span:nth-child(7) {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
		.breadcrumb {
			width: 10%;
			height: 0.3rem;
			margin-left: 0.4rem;
			flex-grow: 1;
		}
	}
}

@media only screen and (max-width: 980px) {
	.breadcrumb-content {
		&__step {
			padding: 0.5rem 2rem;
		}
	}
}

@media only screen and (max-width: 767px) {
	.breadcrumb-content {
		margin-top: 6rem;
		padding: 0 .5rem;
		&__step {
			padding: 0;
		}
		&__back {
			margin-left: 0rem !important;
		}
	}
}
