.otp-content {
	&__title,
	&__text {
		color: $black;
	}
	&__title {
		margin: 8rem 0 1.87rem 0;
		font-family: $semi-bold;
		font-size: $h1-font-size;
	}

	&__inputs {
		.ng-otp-input-wrapper > .otp-input {
			width: 3.5rem;
			height: 3.5rem;
			border-radius: 8px;
			font-size: $h3-font-size;
			font-family: $semi-bold;
			color: $black;
		}
	}

	&__text {
		margin-bottom: 2rem;
		font-size: $h6-font-size;
		font-family: $regular;
		span {
			color: $primary-color;
			font-family: $semi-bold;
		}
	}
}

@media only screen and (max-width: 767px) {
	.otp-content__inputs .ng-otp-input-wrapper > .otp-input {
		width: 3rem;
	}
	.otp-content {
		&__title {
			line-height: normal;
			margin: 6rem 0 1.87rem 0;
		}
	}
}
