@font-face {
    font-family: 'Golos Regular';
    src: url('../../assets/fonts/GolosText-Regular.eot');
    src: url('../../assets/fonts/GolosText-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/GolosText-Regular.woff2') format('woff2'),
        url('../../assets/fonts/GolosText-Regular.woff') format('woff'),
        url('../../assets/fonts/GolosText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Medium';
    src: url('../../assets/fonts/GolosText-Medium.eot');
    src: url('../../assets/fonts/GolosText-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/GolosText-Medium.woff2') format('woff2'),
        url('../../assets/fonts/GolosText-Medium.woff') format('woff'),
        url('../../assets/fonts/GolosText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Semibold';
    src: url('../../assets/fonts/GolosText-SemiBold.eot');
    src: url('../../assets/fonts/GolosText-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/GolosText-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/GolosText-SemiBold.woff') format('woff'),
        url('../../assets/fonts/GolosText-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Bold';
    src: url('../../assets/fonts/GolosText-Bold.eot');
    src: url('../../assets/fonts/GolosText-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/GolosText-Bold.woff2') format('woff2'),
        url('../../assets/fonts/GolosText-Bold.woff') format('woff'),
        url('../../assets/fonts/GolosText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('../../assets/fonts/GolosText-Black.eot');
    src: url('../../assets/fonts/GolosText-Black.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/GolosText-Black.woff2') format('woff2'),
        url('../../assets/fonts/GolosText-Black.woff') format('woff'),
        url('../../assets/fonts/GolosText-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}