// ============================================================================
//
// COMPONENTS - FORM
//
// ============================================================================
.label-input {
	font-size: 0.875rem;
	font-family: $bold;
	font-style: normal;
	font-weight: 600;
}

.error_small {
	color: #e24c4c;
}

.p-inputgroup-addon {
	padding: 9px 0;
}

.dropdown-search {
	.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
		width: 100%;
	}

}

.files-input {
	cursor: pointer;
	position: relative;
	height: auto;
	border: 0;
	color: $primary-color;
	display: flex;
	align-items: center;
	justify-content: space-between;

	input {
		display: none;
	}
	span {
		margin-left: 40px;
	}
}



// Fix selects

.p-dropdown .p-dropdown-label.p-placeholder {
	text-align: left;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
	text-align: left;
	text-overflow: ellipsis;
}

.p-dropdown .p-overlay {
	right: 0;
}

