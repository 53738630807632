// ============================================================================
//
// COMPONENTS - LOADER
//
// ============================================================================

.loader {
	position: fixed;
	z-index: 12;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 24px;
	background-color: rgba($black, 0.8);
	color: white;
	font-size: 24px;

	img {
		width: 170px;
	}
}
