.integration-sdk__content {
    background-color: $white;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .content-iframe {
        height: 100vh;
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
}