.p-sidebar .p-sidebar-header,
.p-sidebar-footer {
	padding: 0 !important;
}

.p-sidebar .p-sidebar-content {
	padding: 44px 33px 0 36px !important;
}

.button-select {
	width: 347px;
	height: 60px;
	border-radius: 8px;
	justify-content: center;
	align-items: center;
	background-color: $primary-color;
	font-size: 16px;
	border: 1px solid $primary-color;
}
.title-sidesheet {
	font-size: 24px;
	color: $title-color-blue;
	font-family: $regular;
	line-height: 120%;
}
.title-2 {
	font-size: 19px;
	color: $black;
	font-family: $regular;
}

.list-benefits {
	list-style: disc;
	color: $black;
	font-size: 19px;
}

.description-text-sidesheet {
	color: $black;
	font-size: 18px;
	font-family: $regular;
	line-height: 22.8px;
}
