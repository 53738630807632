@import 'scss/utils/utils';
@import 'scss/base/all';
@import 'scss/layout/all';
@import 'scss/components/all';
@import 'scss/pages/all';
@import 'scss/themes/themes';

// @import '@angular/material/theming';

    // @import "@angular/material/theming";
    // @include mat-core();

@import "scss/vendors/vendors";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import 'primeflex/primeflex.scss';
@import "primeicons/primeicons.css";


// This is 7-1 pattern: 7 folders, 1 file.
// All your partials stuffed into 7 different folders, and a single file at the root level (usually named main.scss or styles.scss)
// which imports them all to be compiled into a CSS stylesheets.

// You can adapt the pattern to the needs of your project, adding or removing folders or structuring as you consider optimal,
// following the same concept.

// 1.-utils/
// 2.-base/
// 3.-layout/
// 4.-components/
// 5.-pages/
// 6.-themes/
// 7.-vendors/
// And :
// styles.scss
