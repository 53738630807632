// ============================================================================
//
// COMPONENTS - CARD
//
// ============================================================================


.mat-card-subtitle {
  margin-bottom: 0 !important;
}

.mat-card-header-text {
  display: flex;
  align-items: center;
}

.mat-card-header-text {
  margin: 0 20px 0px 10px;
  width: 100%;
}

.card {
  border: none;
  border-radius: 0;
}

.card-disclaimer {
  border-radius: 0.5rem;
  border: 1px dashed $primary-color;
  background-color: rgba(0, 80, 236, 0.06);
  padding: 1rem 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__text {
    color: initial;
    font-size: 1rem;
    line-height: 1.375rem;
  }
  @media only screen and (min-width: 767px) {
    flex-direction: row;
    &__icon {
      margin-right: 0.5rem;
    }
  }
}

// .card {
//   width: 100%;
//   -webkit-box-shadow: $box-shadow;
//   -moz-box-shadow: $box-shadow;
//   box-shadow: $box-shadow;


//   &__col-states {
//     width: $dp * 20;
//   }

//   &__state {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     font-size: $s_xtiny;
//     text-transform: uppercase;
//     padding: $dp * 0.5;
//     border-radius: 0 $default-radius $default-radius 0;
//     height: 100%;
//     align-items: center;

//     &.pending {
//       background: $pending-color;
//     }

//     &.waiting, &.received, &.starting {
//       background: $warning-color;
//     }

//     &.finished {
//       background: $success-color;
//     }

//     &.error {
//       background: $danger-color;
//     }
//   }
// }

.detail-card {
  display: flex;
  flex-wrap: wrap;
  gap: $dp * 1.5 $dp * 2.8;
  margin-bottom: $dp * 2;
  justify-content: left;
  background: $white;
  padding: $dp * 2 $dp * 1.5;

  mat-card {
    min-width: $dp * 13.5;
    padding: 0;
  }

  mat-card-content.card-detail__content {
    height: 100%;
  }

  &__info {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;

    .shop {
      display: flex;
      align-items: center;
      font-weight: 500;

        &__icon {
          margin-right: $dp * 0.6;
          color: $gray-2;
        }
    }
  }

  &__status {
    min-width: 30%;
    display: flex;
    align-items: center;
  }
}
