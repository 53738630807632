.disabled_label {
	opacity: 0.4;
	cursor: no-drop;
}

.line-separate {
	opacity: 0.4;
}


.subtitle{
	font-family: $regular;
	    font-weight: normal;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: $primary-color;
    color: $white;
    border-radius: 16px;
}