.dashboard {
	width: 100%;
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 40px !important;

		padding: 20px 25px;
		border-radius: 10px;
		background: var(--secondary-bg);
		color: var(--secondary-tx-color);

		button {
			.mat-button-wrapper {
				display: flex;
				align-items: center;
				mat-icon {
					height: 100% !important;
					margin-right: 5px;
				}
			}
		}
	}

	&__content {
		display: grid !important;
		grid-gap: 30px !important;
		grid-template-columns: repeat(2, minmax(0, 1fr)) !important;

		.a {
			display: grid !important;
			grid-auto-flow: row !important;
			gap: 2rem !important;
		}
		.b {
			border-radius: 1rem !important;
			overflow: hidden !important;
			flex-direction: column !important;
			flex: 1 1 auto !important;
			display: flex !important;
		}
	}

	&__chart {
		overflow: hidden !important;
		flex-direction: column !important;
		flex: 1 1 auto !important;
		display: flex !important;
		position: relative !important;
		border-radius: 1rem !important;
		background: #fff;

		&__header {
			border-bottom: 2px solid #ececec;
			display: flex;
			align-items: center;
		}

		.h5 {
			margin-bottom: 0 !important;
		}

		apx-chart {
			width: 100% !important;
		}
	}
}

.section {
	&__title {
		letter-spacing: -0.025rem !important;
		line-height: 2rem !important;
		font-weight: 600 !important;
		font-size: 1.5rem !important;
		margin: 0 !important;
	}

	&__description {
		letter-spacing: -0.025rem !important;
		font-weight: 500 !important;
	}
}

// Dashboard w no dependencies

.html-dashboard {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2rem;
		padding: 1rem;
		border-radius: 1.25rem;
		background: var(--secondary-bg);
		color: var(--secondary-tx-color);
	}

	&__header-actions {
	}

	&__header-actions-btn {
		border-radius: 1.5rem;
		border: 0;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding: 0.5rem;
		background-color: var(--btn-global-bg);
		color: var(--btn-tx-color);
	}

	&__content {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-grow: 1;
		gap: 2rem;
	}

	&__content-section {
		width: 36%;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: 2rem;

		&--table-section {
			flex-basis: 26rem;
			flex-grow: 1;
		}
	}

	&__table {
		width: 100%;
		overflow: auto;

		table {
			min-width: 28rem;
		}
	}
}

// Chart

.dashboard-chart {
	width: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 1.25rem;
	background: var(--secondary-bg);
	color: var(--secondary-tx-color);

	&__header {
		padding: 1rem;
		border-bottom: 2px solid #ececec;
		display: flex;
		flex-direction: column;
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	&__data {
		padding: 1rem;
		width: 30%;
		flex-grow: 1;
		gap: 2rem;
	}

	&__graphic {
		flex-basis: 20rem;
		flex-grow: 1;
	}
}
