// ============================================================================
//
//    STYLES LAYOUT
//
// =============================================================================

// Stylesheets for the main parts of the site (header, footer, navigation, sidebar…), and the grid system.

.content {
  height: 100%;
  background-color: $white;
}

.info {
  width: 100%;
  background: #e8e8e8;
  padding: 15px 0;

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

// Basic layout structure

// This is a basic layout structure. The main take 100% of the height of the viewport less the footer and header height, dynamically.

.basic-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &__header {
    width: 100%;
  }

  &__sub-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
  }
  // This will make the main element take the remaining space dynamically.
  &__main {
    width: 100%;
    max-width: $max-container;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 1rem;
    margin: 0 auto;
    gap: 2rem;
  }
  // This will make the aside and section elements responsive without media queries .
  &__aside {
    // flex-basis: 16em;
    // flex-grow: 1;
  }
  &__section {
    flex-basis: 70%;
    flex-grow: 1;
  }
  &__article {
  }
  &__footer {
    height: 4rem;
  }
}

vass-welcome {
  display: block;
  height: 100%;
}
