.debit-content {

	&__title, &__subtitle {
		color: initial;
		text-align: center;

		@media only screen and (max-width: 767px) {
			text-align: left;
			line-height: 1.2;
		}
	}

	&__title {
		margin-bottom: 20px;
		font-family: $semi-bold;
		font-weight: 700;
	}
	&__subtitle {
		font-family: $regular;
	}

	.debit-content__carousel {
		vass-carousel {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__card {
		border-radius: 16px;
		padding: 40px 48px;
		border: 1px solid #E4E4E4;

		.card-title {
			color: initial;
			margin-bottom: 24px;
			font-family: $semi-bold;
			font-weight: 700;	
		}

		.card-text {
			margin-bottom: 36px;
		}

		.card-content {
			background-color: $blue;
			border-radius: 12px;
			color: $white;
			padding: 20px;

			.content-data {
				display: flex;
				flex-direction: row;
				margin-bottom: 20px;
			}
			.data-title, .data-value {
				margin: 0;
			}
			.data-icon {
				margin-right: 20px;
			}
			.data-title {
				margin-bottom: 4px;
				font-family: $semi-bold;
			}

			.content-total { font-family: $semi-bold; }

			.content-message {
				margin-top: 6px;
				margin-bottom: 0;
				font-size: 0.9rem;

				span {
					font-family: $semi-bold;
				}
			}
		}
	}
	@media only screen and (max-width: 767px) {
		.grid {
			.col-12:first-child {
				margin-top: 1rem !important;
			}
			.col-12:nth-child(2) {
				margin-top: 0 !important;
			}
		}
		&__card {
			padding: 1rem;

			.card-title {
				line-height: normal;
			}
			.card-title,
			.card-text {
				margin-bottom: 1rem;
			}
		}
		&__subtitle {
			margin-bottom: .6rem;
		}
		.card-message {
			margin: 1.6rem;
			flex-direction: column;

			img {
				width: 5.5rem;
				margin: 0 auto 1rem;
			}
		}
	}
}



.title {
	margin: 4rem 0 1.87rem 0;
	font-family: "Golos Semibold", "Helvetica Neue", Arial, sans-serif;
	font-size: 2.1875rem;
	color: initial;
	text-align: center;

	@media only screen and (max-width: 767px) {
		margin: 2rem 0;
		text-align: left;
		line-height: 1.2;
		font-size: 1.8rem;
	}
}

.title_step2 {
	margin: 4rem 14rem 1.87rem 14rem;
	font-family: "Golos Semibold", "Helvetica Neue", Arial, sans-serif;
	font-size: 2.1875rem;
	color: initial;
	line-height: initial;
	text-align: center;

	@media only screen and (max-width: 767px) {
		margin: 2rem 0;
		text-align: left;
		line-height: normal;
        font-size: 1.3rem;
	}
}

.subtitle-debit-card {
	margin: 0 12rem;
	text-align: center;
	@media only screen and (max-width: 767px) {
		text-align: left;
		margin: 0 1rem;
	}
}

.mrl-card-personalized {
	margin-left: 20px;
}

@media only screen and (max-width: 767px) {
	.subtitle-debit-card {
		margin: 0;
	}
	.mrl-card-personalized {
		margin-left: 0;
	}
}

.card-message {
	@media only screen and (max-width: 767px) {
		margin: 1rem;
	}
}
