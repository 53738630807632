.soft-content {
	padding-bottom: 7.5rem;

	&__title {
		color: $black;
		font-family: $semi-bold;
		font-size: $h1-font-size;
		text-align: center;
		margin: 3rem 0 1.25rem 0;
		@media only screen and (max-width: 767px) {
			text-align: left;
			font-size: 1.4rem;
		}
	}

	&__resend {
		font-family: $semi-bold;
		color: $primary-color;
		text-decoration: none;
	}

	&__card {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
		border: 1px solid $primary-color;
		border-radius: 16px;
		padding: 24px;
		.card-icon {
			margin-right: 10px;
		} 
		.card-text {
			color: $black;
			font-size: 1.25rem;
			&__apps {
				margin-top: 10px;
				display: flex;
				width: 100%;
				margin-right: 10px;
				.apps-icons {
					width: 90%;
				}
			}
		}
	}

	&__qr {
		--s: 50px;
		--t: 5px; 

		padding: 5px;
		outline: var(--t) solid $primary-color;
		outline-offset: calc(-1*var(--t));
		mask:
			conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
			0 0/calc(100% - var(--s)) calc(100% - var(--s)),
			linear-gradient(#000 0 0) content-box;
		cursor: pointer;
		width: 70%;
	}

	&__modal  {
		.modal-title,
		.modal-text {
			color: $black;
		}

		.modal-title {
			font-size: $h3-font-size;
			font-family: $semi-bold;
		}
		.modal-text {
			font-size: 1rem;
			font-family: $regular;
		}
	}

}