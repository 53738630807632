// ============================================================================
//
// COMPONENTS - TOOLBAR
//
// ============================================================================

.toolbar {
  background: $white;
  color: $text-color;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  box-sizing: border-box;
  padding: 0 !important;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}
