// ============================================================================
//
// COMPONENTS - SIDENAV
//
// ============================================================================

.sidenav-container {
	height: 100%;
}

.sidenav {
	width: 250px;
	&__icon {
		margin-right: 10px;
	}

	&__item {
		&.active {
			.mat-list-item-content,
			.sidenav__icon {
				color: $primary-color;
			}
		}
	}
}

.mat-drawer-content {
	color: var(--global-tx-color);
	overflow: initial;
}

.html-aside {
	position: absolute;
	top: 3.625rem;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: -1;

	transition: all 0.8s ease;

	&__container {
		position: relative;
		transform: translateX(-100%);
		height: 100%;
		width: 20rem;
		z-index: 1;
		box-sizing: border-box;
		-webkit-overflow-scrolling: touch;
		display: block;
		overflow: hidden;
		background: var(--secondary-bg);
		color: var(--global-tx-color);
		box-shadow: $box-shadow;
	}

	&__backdrop {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		// position: absolute;
		display: block;
		z-index: 3;
		visibility: hidden;

		transition-duration: 400ms;
		transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
		transition-property: background-color, visibility;
	}

	&__drawner-side {
		transform: none;
		visibility: visible;
		z-index: 2;
	}

	&__inner-container {
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	&__menu {
	}

	&__menu-item {
		border-top: 1px solid var(--gray-color-3);
		padding: 1rem;
	}

	&__menu-link {
		display: flex;
		align-items: center;
		align-content: center;
		height: 2rem;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		width: 100%;
		padding: 0;
		text-decoration: none;
	}

	&__menu-icon {
		padding: 0.25rem;
	}
}

// Menu visibility modificator
.html-aside--visible {
	background-color: rgb(15 75 122 / 48%);
	opacity: 1;
	z-index: 5;

	.html-aside__container {
		transform: translateX(0);
		transition: 0.3s ease-in-out;
		animation: slide-in 0.3s forwards;
	}
}
