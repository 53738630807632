// * Modals
// * =================================================
.p-dialog {
  &-header,
  &-footer,
  &-content {
    background-color: $white;
    border-radius: 0;
  }

  &-content {
    padding-left: 2rem;
    padding-right: 2rem;
    @media only screen and (max-width: 767px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    p {
      color: $black;
      font-size: $h3-font-size;
      font-family: $semi-bold;
      text-align: center;
    }
  }
}

.p-multiselect-panel {
  .p-multiselect-header {
    .p-checkbox {
      display: none;
    }
  }
}
