.finished-content {
	padding-top: 8rem;


	&__title {
		color: $black;
		font-family: $semi-bold;
		font-size: $h1-font-size;
		text-align: center;
		margin: 1rem 0 1.25rem 0;
	}

	&__text {
		font-family: $regular;

		&.text-email {
			max-width: 600px;
			padding: 0 1rem;
			text-align: center;
			margin-bottom: 50px;
		}
		span {
			font-family: $bold;
		}
	}

	&__card {
		border: 1px solid #B2B2B2;
		border-radius: 16px;
		display: flex;
		justify-content: center;
		width: 550px;
		padding: 27px 10px;
		margin-left: -20px;
		margin-top: 20px;
		margin-bottom: 20px;
		.card-divider-h {
			display: none;
		}
		@media only screen and (max-width: 767px) {
			width: 80%;
			flex-direction: column;
			text-align: left;
			font-size: 1.4rem;
			margin-left: 0;
			.card-divider-v {
				display: none;
			}
			.card-divider-h {
				display: block;
			}
		}

		.card-text {
			text-align: center;
			color: $black;
			&:last-child {
				padding-right: 25px;
				@media only screen and (max-width: 767px) {
					padding-right: 0;
				}
			}
		}
	}

	&__cardOne {
		border: 1px solid #B2B2B2;
		border-radius: 16px;
		display: flex;
		justify-content: center;
		width: 50%;
		padding: 27px 10px;
		margin-top: 40px;
		margin-bottom: 20px;
		@media only screen and (max-width: 767px) {
			width: 80%;
		}
		.card-text {
			text-align: center;
			color: $black;
		}
	}

	&__apps {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 40px;

		a {
			margin-right: 20px;
		}
	}
}
