// ============================================================================
//
//    BASE - TYPO
//
// ============================================================================

// =============================================================================
// Fonts
// =============================================================================

// Font size
$font-sizes: (
  11px,
  // $s_xtiny
  12px,
  // footer - $s_tiny
  14px,
  // h5, h6, buttons - $s_small
  16px,
  //$base-font-size - h4 - $s_normal
  18px,
  // h3 - $s_medium
  24px,
  // h2 - $s_large
  36px,
  // h1 - $s_xlarge
);

$s_xtiny: nth($font-sizes, 1);
$s_tiny: nth($font-sizes, 2);
$s_small: nth($font-sizes, 3); // h5, h6
$s_normal: nth($font-sizes, 4); // $base-font-size - h4
$s_medium: nth($font-sizes, 5); // h3
$s_large: nth($font-sizes, 6); // h2
$s_xlarge: nth($font-sizes, 7); // h1

h1 {
  font-size: $s_xlarge;
}

h2 {
  font-size: $s_large;
}

h3 {
  font-size: $s_medium;
}

h4 {
  font-size: $s_normal;
}

h5,
h6 {
  font-size: $s_small;
}

p {
  font-size: $s_normal;
}

body,
h1,
h2,
h3,
h4,
p,
th,
td,
ul[class],
ol[class],
li,
a {
  color: inherit;
}


// helpers

.text-secondary {
    color:#6c757d !important;
}
