.p-button-success {
	width: 100%;
	height: 60px;
	border-radius: 35px;
	background-color: #FFF;
	border: 2px solid #1dc57d;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	
	&-active {
		background: #1dc57d;
		color: #1f2d5e;
	}
	@media only screen and (min-width: 768px) {
		max-width: 270px;
	}
}

.p-button-success:focus {
	background: #1dc57d;
	color: #1f2d5e;
}
.p-button-success:active {
	background: #1dc57d;
	color: #1f2d5e;
}
.p-button-success-active {
	background-color: #1dc57d;
	color: #1f2d5e;
}

.received-options {
	display: flex;
	gap: 1.5rem;
	justify-content: center;
	flex-direction: column;
	@media only screen and (min-width: 768px) {
		flex-direction: row;
		transform: scale(0.95);
	}
}
