.message-container {
	.message-icon {
		width: 50px;
	}
	  
	.message-title {
		text-align: center;
		margin: 1rem 0;
	}
	  
	.message-subtitle {
		width: 65%;
		text-align: center;
		margin: 0 auto;
	}
}