.verify-content {
	position: relative;
	
	&__title {
		color: $black;
		font-family: $semi-bold;
		font-size: $h1-font-size;
		text-align: center;
		margin: 3rem 0 1.2rem 0;

		@media only screen and (max-width: 767px) {
			text-align: left;
		}
	}

	&__subtitle {
		text-align: center;
		font-family: $regular;
		font-size: $h4-font-size;
		margin-bottom: 2rem;
		@media only screen and (max-width: 767px) {
			text-align: left;
			font-size: $h5-font-size;
			line-height: 1.2;
		}
	}
	&__cardInfoWithoutSelection {
		background-color: transparent;
		position: relative;
		padding: 30px;
		background-image: url('../../assets/images/global/back-ground-identity.png');
		background-size: cover;
		height: 110%;

		.info-image {
			display: initial;
			width: 100%;
			padding-top: 50px;
		}

		.p-card {
			.p-card-content {
				padding: 0;
			}
			.p-card-body {
				height: 0;
				padding: 0;
			}
		}

		.cardInfo-text {
			color: $black;
		}
	}

	&__cardInfo {
		background-color: transparent;
		border: 3px solid rgba($primary-color, 0.5);
		border-radius: 10px;
		cursor: pointer;
		position: relative;
		padding: 0;

		.info-image {
			display: block;
			width: 60%;
			margin: 2.4rem auto;
		}

		.p-card {
			.p-card-content {
				padding: 0;
			}
			.p-card-body {
				height: 60px;
				padding: 15px;
			}
		}
		.cardInfo-icon {
			display: none;
		}
		.cardInfo-btn {
			border: 1px solid $primary-color;
			background-color: transparent;
			border-radius: 5px;
			color: $black;
			font-weight: bolder;
			width: 30px;
			height: 30px;
		}

		.cardInfo-text {
			color: $black;
		}

		&:focus, &:hover {
			border: 4px solid rgba($primary-color, 0.5);
			.cardInfo-icon {
				display: block;
				position: absolute;
				top: 2px;
				right: 6px;
				color: white;
			}
			.cardInfo-btn {
				background-color: $primary-color;
				color: $white;
			}
			&::before {
				content: "";
				position: absolute;
				clip-path: polygon(100% 0, 0 0, 100% 100%);
				top: -4px;
				right: -4px;
				background-color: $primary-color;
				border-top-right-radius: 10px;
				padding: 28px;
				width: 40px;
				height: 40px;
			}

			.p-card {
				.p-card-body {
					background-color: rgba($primary-color, 0.2);
				}
			}

		}
	}

	&__cardInfo--selected {
		border: 4px solid rgba($primary-color, 0.5);
		.cardInfo-icon {
			display: block;
			position: absolute;
			top: 2px;
			right: 6px;
			color: white;
		}
		.cardInfo-btn {
			background-color: $primary-color;
			color: $white;
		}
		&::before {
			content: "";
			position: absolute;
			clip-path: polygon(100% 0, 0 0, 100% 100%);
			top: -4px;
			right: -4px;
			background-color: $primary-color;
			border-top-right-radius: 10px;
			padding: 28px;
			width: 40px;
			height: 40px;
		}

		.p-card {
			.p-card-body {
				background-color: rgba($primary-color, 0.2);
			}
		}
	}

	&__card {
		background-color: rgba(0, 80, 236, 0.06);
		border-radius: 0.5rem;
		border: 2px dashed $primary-color;
		padding: 1rem 2rem 1rem 2rem;
		width: 100%;
		// margin-bottom: 4rem;

		.card-title,
		.card-text {
			color: $black;
			font-family: $regular;
		}
		.card-title {
			font-size: $h6-font-size;
			font-family: $bold;
			text-align: center;

			@media only screen and (max-width: 767px) {
				text-align: left;
				font-size: 0.9rem;
			}
		}
		.card-icon__first,
		.card-icon__second {
			font-size: 1.8rem;
			margin-right: 10px;
		}
		.card-icon__first {
			color: $blue;
		}
		.card-icon__second {
			color: $green;
		}
		.card-text {
			font-size: 0.9rem;
		}
	}

	@media only screen and (max-width: 767px) {
		&__title {
			margin: 1rem .8rem;
		}
		&__subtitle {
			margin: 0 .8rem;

			.subTitle {
				line-height: normal !important;
			}
		}
		.containerCards {
			.contentCard {
				.cardDetail {
					.card-wrapper {
						text-align:center;
					}
					.textContent {
						line-height: normal !important;
					}
					.p-card-header{
						.info-image {
							margin: 1rem auto 0 !important;
							width: 75%;
						}
					}
					.p-card-body {
						height: auto;
						padding: 0;
						padding: 1rem 0 .5rem .5rem;
					}
				}
			}
		}
	}
}
