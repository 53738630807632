// ============================================================================
//
// COMPONENTS - USER
//
// ============================================================================

.user__menu {
	padding: 12px 15px;
	display: flex;
	align-items: center;

	&__image {
		background-size: cover;
		border-radius: 20px;
	}

	.relative {
		display: flex;
		gap: 15px;
	}
}
