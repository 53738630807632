// ============================================================================
//
//    THEMES
//
// ============================================================================

// Styles for different visual themes.

p-dropdown.ng-invalid.ng-dirty {
	border: 0.4px solid #e23c4c !important;
	border-radius: 6px;
}

p-dropdown:hover {
	border: none !important;
}
