.financial-content {

	&__title {
		color: $black;
		font-family: $semi-bold;
		font-size: $h1-font-size;
		text-align: center;
		margin: 3rem 0;
	}
    
    .card {
        width: 420px;
        height: 100vh;
    }
    
    .trash-icon {
        cursor: pointer;
        width: 23px;
    }
    
    .button-add {
        &__label {
            color: $primary-color;
            font-family: $semi-bold;
        }
    }
    
    @media only screen and (max-width: 767px) {
        .p-overlay-content {
            width: 100% !important;
        }
        .job-content {
            &__title {
                margin-bottom: 1.5rem;
                margin-top: .5rem;
                line-height: normal;
            }
        }
        .label-input:first-child {
            margin: 0 .5rem 1.3rem;
        }
        .xs-device {
            margin: 0;
            display: grid;
            grid-template-columns: 33% 33% 33%;
            gap: 0.5rem;

            label {
                margin: 0 !important;
            }
        }
    }
}