// ============================================================================
//
// COMPONENTS - TABLE
//
// ============================================================================

.table,
table {
  background-color: #fff;
  img {
    margin: 0 auto;
  }

  &__tr {
    &__header {
      background: $gray-3;
    }

    &__body {
      &:hover {
        background: $hover-color;
      }
    }
  }

  .mat-cell {
    padding-top: 14px !important;
  }

  .mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding: 1rem 0.5rem !important;
  }

  &__th {
    text-transform: uppercase;
    color: $text-color;
    font-size: $s_tiny;

    &.mat-column-estado {
      color: transparent;
    }
  }

  &__col-states {
    width: $dp * 20;
  }

  &__state {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: $s_tiny;
    text-transform: uppercase;
    padding: $dp * 0.5;
    border-radius: $default-radius;

    &.pending {
      background: $pending-color;
    }

    &.waiting,
    &.received,
    &.starting {
      background: $warning-color;
    }

    &.finished {
      background: $success-color;
    }

    &.error {
      background: $danger-color;
    }
  }

  &__no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $white;

    &__img {
      width: $dp * 15;
      margin-bottom: $dp * 4;
    }

    &__icon {
      width: 100% !important;
      height: auto !important;
      font-size: $dp * 10;
      margin-bottom: $dp * 4;
      text-align: center;
      color: $gray-3;
    }

    &__title {
      font: 600 24px/20px Roboto, "Helvetica Neue", sans-serif !important;
    }

    &__info {
      font: 400 16px/20px Roboto, "Helvetica Neue", sans-serif !important;
    }
  }
}

// html table
.div-table {
  display: table;
  width: 100%;
  font-size: 0.9rem;
  text-align: right;
  margin-top: -1px;
  &__body {
    display: table-row-group;
  }

  &__row {
    display: table-row;
    &--heading {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }

  &__cell {
    border-top: 1px solid var(--gray-color-3);
    display: table-cell;
    padding: 1.04rem;
    font-size: 0.84rem;
    white-space: nowrap;

    &--status {
      font-weight: normal;
      color: #fff;
    }
  }
}
